import moment from "moment";

export const getYMD = (date1, date2) => {
    const a = moment(date1);
    const b = moment(date2);
    var years = a.diff(b, 'year');
    b.add(years, 'years');
    const noOfDaysInb = b.daysInMonth();
    const noOfDaysIna = a.daysInMonth();
    let months = 0;
    if (noOfDaysInb > noOfDaysIna) {
        months = b.diff(a, "months");
        a.add(months, "months");
    } else {
        months = a.diff(b, 'months');
        b.add(months, 'months');
    }
    var days = a.diff(b, 'days');
    
    return {
        years: Math.abs(years),
        months: Math.abs(months),
        days: Math.abs(days),
    }
}