import React from 'react'
import Slider from 'react-slick'
import moment from 'moment'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import Layout from '../../components/layout'
import Head from '../../components/head'
import { getEvents, getEventSliderData } from '../../http_service'
import { getYMD } from '../../utils/common'
import NoDataImg from '../../assets/images/no_image.png'
// import Culture from '../../assets/images/culture.png'
import './style.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Error from '../../components/error'
import { createMarkup } from '../../utils/common'

const getFormattedDate = (startDate, endDate) => {
  let formattedDate = ''
  const { months, days } = getYMD(startDate, endDate)

  if (months > 0) {
    //Difference in number of months
    const startDayMonthName = moment(startDate).format('MMMM')
    const endDayMonthName = moment(endDate).format('MMMM')
    const startDateDay = moment(startDate).format('D')
    const endDateDay = moment(endDate).format('D')

    formattedDate = `${startDayMonthName} ${startDateDay} - ${endDayMonthName} ${endDateDay}`
  } else if (days >= 1) {
    //Difference in number of days
    const startDateDay = moment(startDate).format('D')
    const endDateDay = moment(endDate).format('D')
    const monthName = moment(startDate).format('MMMM')

    formattedDate = `${monthName} ${startDateDay} - ${endDateDay}`
  } else if (days === 0) {
    // Same day
    formattedDate = moment(startDate).format('dddd, MMMM D, YYYY')
  }

  return formattedDate
}

const EventsPage = ({ serverData }) => {
  const events = _get(serverData, 'events', [])
  const eventsSliderData = _get(serverData, 'eventsSliderData', [])

  if (serverData.error) {
    console.log('serverData-events', serverData)
    return <Error />
  }

  const settings = {
    dots: true,
    slidesToShow: 1,
  }

  return (
    <>
      <Head title="Events Page" />
      <Layout>
        <>
          {!_isEmpty(eventsSliderData) && (
            <div className="events-slider-container">
              <Slider {...settings}>
                {eventsSliderData.map((sliderItem) => {
                  const {
                    name: title,
                    start_date: startDate,
                    image_full_url: imageURL,
                    description_plain: description,
                    slug,
                  } = _get(sliderItem, 'event', {})
                  const date = moment(startDate).format('dddd, MMMM D, YYYY')
                  const startTime = moment(startDate).utc().format('hh:mm A')
                  const bannerImg = imageURL ? imageURL : NoDataImg

                  return (
                    <div key={sliderItem.event.id} className="event-content">
                      <div className="info-content">
                        <h2 className="title">{title}</h2>
                        <div
                          itemProp="description"
                          className="description"
                          dagerouslySetInnerHnTML={createMarkup(description)}
                        />
                        <p className="date-info">{`${date}, ${startTime}`}</p>
                        <a className="buy-ticket" href={`/events/${slug}`}>
                          Buy Tickets
                        </a>
                      </div>
                      <div className="img-content">
                        <img src={bannerImg} alt="" />
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
          <div className="event-list-section">
            <h1 className="week-info">THIS WEEK</h1>
            <div className="line-divider" />
            <div className="events-list-container">
              {events.map((event) => {
                const {
                  name: displayName,
                  start_date: startDate,
                  end_date: endDate,
                  image_full_url: imageURL,
                  description_plain: description,
                  slug,
                  //venue: { displayName: venueName, city },
                  venue: { name: venueName, city },
                  minimum_age: minimumAge,
                } = event //_get(event, 'attributes', {})

                const date = getFormattedDate(startDate, endDate)
                const startTime = moment(startDate).utc().format('hh:mm A')
                const image = imageURL ? imageURL : NoDataImg
                const minAgeValue = minimumAge
                  ? `[Ages ${minimumAge} +]`
                  : '[All Ages]'
                const venueFullAddress = `${venueName} , ${city}`

                return (
                  <div
                    className="event-row-item"
                    key={event.id}
                    itemScope
                    itemType="http://schema.org/Event"
                  >
                    <div className="info-section">
                      <div className="image-section">
                        <img src={image} alt="No Data" />
                        {/* <img className='image-type' src={Culture} /> HIDE TEMP */}
                      </div>
                      <div className="details-section">
                        <p className="event-info">{`${date}, Event starts ${startTime}`}</p>
                        <div className="title">
                          <h2 className="event-name">{displayName}</h2>
                          {/* <p className='type'>Mana Fashion</p> HIDE TEMP */}
                        </div>
                        <p className="venue-info-block">{`${venueFullAddress} ${minAgeValue}`}</p>
                        <div
                          itemProp="description"
                          className="description"
                          dagerouslySetInnerHnTML={createMarkup(description)}
                        />
                      </div>
                    </div>
                    <div className="actions-section">
                      <a className="btn" href={`/events/${slug}`}>
                        Buy Tickets
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      </Layout>
    </>
  )
}

export async function getServerData() {
  try {
    const events = await getEvents()
    const eventsSliderData = await getEventSliderData()

    return {
      props: { events, eventsSliderData },
    }
  } catch (error) {
    return {
      props: { error: true, message: error },
    }
  }
}

export default EventsPage
